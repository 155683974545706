import React from "react";
import { useStaticQuery, Link, graphql } from "gatsby";

export default () => {
  const data = useStaticQuery(
    graphql`
      query title {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  );
  return (
    <footer id="footer">
      {/*}
      <section>
        <h2>Aliquam sed mauris</h2>
        <p>
          Sed lorem ipsum dolor sit amet et nullam consequat feugiat consequat
          magna adipiscing tempus etiam dolore veroeros. eget dapibus mauris.
          Cras aliquet, nisl ut viverra sollicitudin, ligula erat egestas velit,
          vitae tincidunt odio.
        </p>
        <ul className="actions">
          <li>
            <Link to="/generic" className="button">
              Learn More
            </Link>
          </li>
        </ul>
      </section>
      <section>
        <h2>Kontakt</h2>
        <dl className="alt">
          <dt>Address</dt>
          <dd>1234 Somewhere Road &bull; Nashville, TN 00000 &bull; USA</dd>
          <dt>Phone</dt>
          <dd>(000) 000-0000 x 0000</dd>
          <dt>Email</dt>
          <dd>
            <a href="#">information@untitled.tld</a>
          </dd>
        </dl>
        <ul className="icons">
          <li>
            <a href="#" className="icon fa-twitter alt">
              <span className="label">Twitter</span>
            </a>
          </li>
          <li>
            <a href="#" className="icon fa-facebook alt">
              <span className="label">Facebook</span>
            </a>
          </li>
          <li>
            <a href="#" className="icon fa-instagram alt">
              <span className="label">Instagram</span>
            </a>
          </li>
          <li>
            <a href="#" className="icon fa-github alt">
              <span className="label">GitHub</span>
            </a>
          </li>
          <li>
            <a href="#" className="icon fa-dribbble alt">
              <span className="label">Dribbble</span>
            </a>
          </li>
        </ul>
      </section>
      <p className="copyright">
        &copy; {new Date().getFullYear()}{" "}
        <a href="https://www.friedmed.ch">Friedmed</a>.
      </p>
  */}
      <p className="copyright">
        &copy; {new Date().getFullYear()}{" "}
        <a href="https://www.friedmed.ch">Friedmed</a>.
      </p>
    </footer>
  );
};
