import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Spotlight from "./templates/Spotlight";
import Default from "./templates/Default";
import { getSectionIds } from "../../generated-graphql-types";

function getSpecificSectionComponent(layoutType: string): React.ElementType {
  switch (layoutType) {
    case "spotlight":
      return Spotlight;
    default:
      return Default;
  }
}

export default function Sections() {
  const data = useStaticQuery<getSectionIds>(
    graphql`
      query getSectionIds {
        sections: allMarkdownRemark(
          filter: { fields: { collection: { eq: "section" } } }
          sort: { order: ASC, fields: frontmatter___position }
        ) {
          edges {
            node {
              id
              frontmatter {
                position
                title
                content {
                  titleText
                  titleAlign
                  type
                  body
                  cv
                  publications
                  image
                  sectionLink
                  columns {
                    icon
                    body
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  return (
    <>
      {data.sections.edges.map(s => {
        const SectionContent = getSpecificSectionComponent(
          s.node.frontmatter.content[0].type
        );
        return (
          <section
            id={s.node.id}
            className={`main ${
              s.node.frontmatter.content[0] &&
              s.node.frontmatter.content[0].titleAlign === "center"
                ? "special"
                : ""
            }`}
            key={s.node.id}
          >
            <SectionContent content={s.node.frontmatter.content[0]} />
          </section>
        );
      })}
    </>
  );
}
