import React from "react";
import { Link } from "gatsby";

import { getSectionIds_sections_edges_node_frontmatter_content } from "../../../generated-graphql-types";
import MarkdownContent from "../../MarkdownContent";

interface SpotlightProps {
  content: getSectionIds_sections_edges_node_frontmatter_content;
}

export default function ({ content }: SpotlightProps) {
  const { titleText, titleAlign, image, body, cv, publications } = content;
  return (
    <div className="spotlight">
      <div className="content">
        <header className="major">
          <h2>{titleText}</h2>
        </header>
        <MarkdownContent content={body} />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {cv ? (
            <a href={cv} className="button" type="application/pdf">
              CV
            </a>
          ) : null}
          {publications ? (
            <a href={publications} className="button" type="application/pdf">
              Publikationen
            </a>
          ) : null}
          <a
            href="https://www.linkedin.com/in/niklaus-f-friederich-039517b"
            className="button"
          >
            LinkedIn
          </a>
          <a
            href="https://www.researchgate.net/profile/Niklaus_Friederich"
            className="button"
          >
            ResearchGate
          </a>
        </div>
      </div>
      <span className="image">
        <img src={image} alt="" />
      </span>
    </div>
  );
}
