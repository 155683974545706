import React from "react";
import PropTypes from "prop-types";
import showdown from "showdown";

const converter = new showdown.Converter();

type MarkdownContentProps = {
  content: string;
  className?: string;
};

/**
 * Should avoid using this component, since this comes with a MD --> HTML converter that is shipped then to the client.
 * But unfortunately, Gatsby doesn't work together with nested Netlify CMS MD content!
 * @param param0
 */
const MarkdownContent = ({ content, className }: MarkdownContentProps) => (
  <div
    className={className}
    dangerouslySetInnerHTML={{ __html: converter.makeHtml(content) }}
  />
);

MarkdownContent.propTypes = {
  content: PropTypes.string,
  className: PropTypes.string
};

export default MarkdownContent;
