import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Waypoint } from "react-waypoint";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Header from "../components/Header";
import Nav from "../components/Nav";
import { getLandingPage } from "../generated-graphql-types";
import Sections from "../components/Sections/Sections";

export const query = graphql`
  query getLandingPage {
    site {
      siteMetadata {
        title
      }
    }
    landingPage: file(name: { eq: "landing" }) {
      name
      markdown: childMarkdownRemark {
        fields {
          collection
        }
        html
        frontmatter {
          ...HeaderInformation
        }
      }
    }
  }
`;

export default function Landing(props: { data: getLandingPage }) {
  const [stickyNav, setStickyNav] = useState(false);
  const { site, landingPage } = props.data;

  function _handleWaypointEnter() {
    setStickyNav(false);
  }

  function _handleWaypointLeave() {
    setStickyNav(true);
  }
  return (
    <Layout>
      <Helmet title={site.siteMetadata.title} />

      <Header data={landingPage.markdown.frontmatter} />

      <Waypoint
        onEnter={_handleWaypointEnter}
        onLeave={_handleWaypointLeave}
      ></Waypoint>
      <Nav sticky={stickyNav} />

      <div id="main">
        <Sections />
      </div>
    </Layout>
  );
}
