import React from "react";
import Scrollspy from "react-scrollspy";
import { useStaticQuery, graphql } from "gatsby";

import Scroll from "./Scroll";
import { getSectionTitles } from "../generated-graphql-types";

interface NavProps {
  sticky: boolean;
}

export default function({ sticky }: NavProps) {
  const data = useStaticQuery<getSectionTitles>(
    graphql`
      query getSectionTitles {
        sections: allMarkdownRemark(
          filter: { fields: { collection: { eq: "section" } } }
          sort: { order: ASC, fields: frontmatter___position }
        ) {
          edges {
            node {
              id
              frontmatter {
                title
              }
            }
          }
        }
      }
    `
  );

  const { sections } = data;
  return (
    <nav id="nav" className={sticky ? "alt" : ""}>
      <Scrollspy
        items={sections.edges.map(s => s.node.id)}
        currentClassName="is-active"
        offset={-300}
      >
        {sections.edges.map(s => (
          <li key={s.node.id}>
            <Scroll type="id" element={s.node.id}>
              <a href="#">{s.node.frontmatter.title}</a>
            </Scroll>
          </li>
        ))}
      </Scrollspy>
    </nav>
  );
}
