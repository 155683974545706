import React from "react";
import "../assets/scss/main.scss";
// import "@fortawesome/fontawesome-free/css/all.css";

import Footer from "./Footer";

type TemplateState = {
  loading: string;
  timeoutId?: NodeJS.Timeout;
};

class Template extends React.Component {
  state: TemplateState = {
    loading: "is-loading"
  };

  componentDidMount() {
    this.state.timeoutId = setTimeout(() => {
      this.setState({ loading: "" });
    }, 100);
  }

  componentWillUnmount() {
    if (this.state.timeoutId) {
      clearTimeout(this.state.timeoutId);
    }
  }

  render() {
    const { children } = this.props;

    return (
      <div className={`body ${this.state.loading}`}>
        <div id="wrapper">
          {children}
          <Footer />
        </div>
      </div>
    );
  }
}

export default Template;
