import React from "react";
import { graphql } from "gatsby";

export default function Header({ data }) {
  return (
    <header id="header" className="alt">
      {/*
      <span className="logo">
        <div>
          <img src={data.profileImage} alt="" />
        </div>
      </span>
      <h1>{data.title}</h1>
      <p>{data.subtitle}</p>
      */}
    </header>
  );
}

export const query = graphql`
  fragment HeaderInformation on MarkdownRemarkFrontmatter {
    title
    subtitle
    profileImage
  }
`;
