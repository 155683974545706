import React from "react";
import { Link } from "gatsby";

import { getSectionIds_sections_edges_node_frontmatter_content } from "../../../generated-graphql-types";
import MarkdownContent from "../../MarkdownContent";

interface SpotlightProps {
  content: getSectionIds_sections_edges_node_frontmatter_content;
}

export default function({ content }: SpotlightProps) {
  const { titleText, columns, sectionLink } = content;
  return (
    <>
      <header className="major">
        <h2>{titleText}</h2>
      </header>
      {columns.length > 1 ? (
        <ul className="features">
          {columns.map((c, idx) => (
            <li key={idx}>
              {c.icon ? (
                <span
                  className={`icon major style${(idx + 1) % 3} fas fa-${
                    c.icon
                  }`}
                ></span>
              ) : null}
              <MarkdownContent content={c.body} />
            </li>
          ))}
        </ul>
      ) : (
        columns[0] && <MarkdownContent content={columns[0].body} />
      )}
      {sectionLink ? (
        <footer className="major">
          <ul className="actions">
            <li>
              <Link to="/generic" className="button">
                Learn More
              </Link>
            </li>
          </ul>
        </footer>
      ) : null}
    </>
  );
}
